import React, { useState, useEffect } from 'react';

function CustomCountdown({ duration, onFinish,isStart }) {
    const [timeLeft, setTimeLeft] = useState(duration);

    useEffect(() => {
        if(isStart){
            const timer = setInterval(() => {
                setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }

    }, []);

    useEffect(() => {
        if (timeLeft === 0) {
            onFinish();
        }
    }, [timeLeft, onFinish]);

    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;

    return (
        <div>
            <span>{hours.toString().padStart(2, '0')}:</span>
            <span>{minutes.toString().padStart(2, '0')}:</span>
            <span>{seconds.toString().padStart(2, '0')}</span>
        </div>
    );
}

export default CustomCountdown;
