import {createSlice} from '@reduxjs/toolkit'



const initialState = {
    activeSource: 'webcam',
    cameraStatus: 'closed',
    isFlashing: false,
    request: {
        status: null,
        code: 0,
        msg: null,
        url: null,
        screenshot: null,
    },
    user: null,
    error: {
        login: {
            screenshot: null,
        }
    }
}


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setActiveTab: (state, action) => {
            state.activeTab = action.payload
        },
        setCameraStatus: (state, action) => {
            state.cameraStatus = action.payload
        },
        setIsFlashing: (state, action) => {
            state.isFlashing = action.payload
        },
        setScreenshot: (state, action) => {
            state.request.screenshot = action.payload
        },
        setAuthError: (state, action) => {
            Object.keys(action.payload).map(s => {
                return Object.keys(action.payload[s]).map(v => {
                    return state.error[s][v] = action.payload[s][v]
                })
            })
        },

    },
})

export const getActiveSource = state => state.auth.activeSource
export const getCameraStatus = state => state.auth.cameraStatus
export const getIsFlashing = state => state.auth.isFlashing
export const getScreenshot = state => state.auth.request.screenshot
export const getURL = state => state.auth.request.url

export const {
    setActiveSource,
    setCameraStatus,
    setIsFlashing,
    setScreenshot,
    setAuthError,
    setURL
} = authSlice.actions
export default authSlice.reducer