import React from 'react';
import { Card } from "antd";
import './subject-item.css'
import {NavLink} from "react-router-dom";

const ResourcesItem = ({subject}) => {
    const title = (
        <div>
            <p className='text-1xl text-white' style={{fontSize:'15px',whiteSpace:'wrap'}}>{subject?.subject_name}</p>
            <p className='text-[12px] text-white' style={{fontSize:'12px'}}>{subject?.curriculum_name} | {subject?.credit} (kredit)</p>
        </div>
    )
    return (
        <Card
            size="large"
            title={title}
            style={{
                maxWidth: 450,
                width: '100%'
            }}
        >
            <NavLink style={{width: '100%', color: "black"}} to={`/resources-list/${subject?.id}`}>
    <div  style={{height:50,display:'flex',alignItems:'center',cursor:'pointer',marginBottom:10}}>
        <p  style={{fontSize:15,fontWeight:'bolder'}}>Resurslar</p>
    </div>
            </NavLink>
            <hr/>
                <NavLink style={{width: '100%', color: "black"}} to={`/task/${subject?.id}`}>
    <div  style={{height:50,display:'flex',alignItems:'center',cursor:"pointer",marginTop:10}}>
        <p  style={{fontSize:15,fontWeight:'bolder'}}>Topshiriqlar</p>
    </div>
                </NavLink>
</Card>
)
    ;
};

export default ResourcesItem;