// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile {
  display: flex;
  gap: 15px;
}
.profile .img {
  width: 297px;
  height: 382px;
  border-radius: 10px;
  overflow: hidden;
}
.profile .img img {
  width: 100%;
  height: 100%;
}
.profile .forms {
  width: 100%;
  max-width: 500px;
}
.profile .changing-pass {
  margin-top: 10px;
}
.profile .changing-pass .image {
  display: none !important;
}
.profile .title {
  font-size: 20px;
}
.profile .submit-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 800px) {
  .profile {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/Profile/Profile.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;AACF;AAAE;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AAEJ;AADI;EACE,WAAA;EACA,YAAA;AAGN;AAAE;EACE,WAAA;EACA,gBAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;AADI;EACE,wBAAA;AAGN;AACE;EACE,eAAA;AACJ;AACE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AACJ;;AAEA;EACE;IACE,aAAA;IACA,8BAAA;IACA,mBAAA;EACF;AACF","sourcesContent":[".profile {\r\n  display: flex;\r\n  gap: 15px;\r\n  .img{\r\n    width: 297px;\r\n    height: 382px;\r\n    border-radius: 10px;\r\n    overflow: hidden;\r\n    img{\r\n      width: 100%;\r\n      height: 100%;\r\n    }\r\n  }\r\n  .forms{\r\n    width: 100%;\r\n    max-width: 500px;\r\n  }\r\n  .changing-pass {\r\n    margin-top: 10px;\r\n    .image {\r\n      display: none !important;\r\n    }\r\n  }\r\n\r\n  .title {\r\n    font-size: 20px;\r\n  }\r\n  .submit-btn{\r\n    width: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: flex-end;\r\n  }\r\n}\r\n@media (max-width: 800px) {\r\n  .profile{\r\n    display: flex;\r\n    flex-direction: column-reverse;\r\n    align-items: center;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
