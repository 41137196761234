// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 2px solid #3c8dbc;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
}
.box-title {
  margin-bottom: 0;
  padding: 15px 0;
}
.box table {
  width: 100%;
}
.box tr {
  width: 100% !important;
}
.box tr:nth-child(even) {
  background: #f4f4f4;
}
.box tr:hover {
  background: #1677ff;
  color: #fff;
}
.box th {
  padding: 5px !important;
  box-sizing: border-box;
  height: 30px;
  width: 40% !important;
  text-align: start;
  border-right: 1px solid #f4f4f4 !important;
}
.box td {
  padding: 5px !important;
  box-sizing: border-box;
  width: 60%;
  white-space: normal;
}`, "",{"version":3,"sources":["webpack://./src/PrivateInfo/PrivateInfo.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,6BAAA;EACA,mBAAA;EACA,WAAA;EACA,gDAAA;AACF;AAAE;EACE,gBAAA;EACA,eAAA;AAEJ;AACA;EACE,WAAA;AACF;AACA;EACE,sBAAA;AACF;AAEA;EACE,mBAAA;AAAF;AAEA;EACE,mBAAA;EACA,WAAA;AAAF;AAEA;EACE,uBAAA;EACA,sBAAA;EACA,YAAA;EACA,qBAAA;EACA,iBAAA;EACA,0CAAA;AAAF;AAEA;EACE,uBAAA;EACA,sBAAA;EACA,UAAA;EACA,mBAAA;AAAF","sourcesContent":[".box {\r\n  position: relative;\r\n  border-radius: 3px;\r\n  background: #ffffff;\r\n  border-top: 2px solid #3c8dbc;\r\n  margin-bottom: 20px;\r\n  width: 100%;\r\n  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);\r\n  &-title {\r\n    margin-bottom: 0;\r\n    padding: 15px 0;\r\n  }\r\n\r\ntable{\r\n  width: 100%;\r\n}\r\ntr{\r\n  width: 100% !important;\r\n\r\n}\r\ntr:nth-child(even){\r\n  background: #f4f4f4;\r\n}\r\ntr:hover{\r\n  background: #1677ff;\r\n  color: #fff;\r\n}\r\nth {\r\n  padding: 5px !important;\r\n  box-sizing: border-box;\r\n  height: 30px;\r\n  width: 40% !important;\r\n  text-align: start;\r\n  border-right: 1px solid #f4f4f4 !important;\r\n}\r\ntd {\r\n  padding: 5px !important;\r\n  box-sizing: border-box;\r\n  width: 60%;\r\n  white-space: normal;\r\n}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
