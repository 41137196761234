import { useState } from "react"
import { useCallback, useEffect } from "react"
import { isMobileSafari, isIOS, isChrome } from "react-device-detect"

export const triggerFullscreen = () => {
  const methods = [
    "requestFullscreen",
    "webkitRequestFullscreen",
    "webkitRequestFullScreen",
    "mozRequestFullScreen",
    "msRequestFullscreen"
  ]

  const ref = document.body
  if (ref) {
    for (const name of methods) {
      if (name in ref) {
        ref[name]()?.catch(err => {
          // console.error(err)
        })
        ref.style.overflowY = "auto"
        break
      }
    }
  }
}

export function useFullScreenDetection({ disabled } = { disabled: false }) {
  /**
   * `undefined` signifies that we have not determined if the browser is in fullscreen mode or not.
   * `true` means that the browser is in fullscreen mode.
   * `false` means that the browser is not in fullscreen mode.
   */
  const [fullScreenStatus, setFullScreenStatus] = useState("pending")

  const changeFullscreenStatus = useCallback(() => {
    setFullScreenStatus(
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
            ? "on"
            : "off"
    )
  }, [])

  const listener = useCallback(e => {
    /**
     * This code is for preventing user from ENTERING full screen mode using keyboard.
     * Browsers don't allow us to prevent user from exiting full screen.
     * Because in that case, the `fullscreenchange` does not fire and our state becomes invalid. The browser becomes full screen but our `status` still says 'off'.
     * The `fullscreenchange` event only fires when we use JavaScript to trigger full screen.
     * https://stackoverflow.com/questions/21103478/fullscreenchange-event-not-firing-in-chrome#:~:text=There%20appears%20to%20be%20a%20security%20restriction%20that%20prevents%20JavaScript%20from%20monitoring%20if%20a%20user%20manually%20enables%20fullscreen%20mode%20via%20a%20hotkey.
     */
    if (["F11", "Escape"].includes(e.key)) {
      e.preventDefault()
    }
  }, [])

  useEffect(() => {
    if (disabled) {
      setFullScreenStatus("pending")
      return
    }

    /**
     * 'Mobile Safari' and 'iOS Chrome' don't support fullscreen.
     */
    if (isMobileSafari || (isChrome && isIOS)) {
      setFullScreenStatus("not-supported")
      return
    }

    changeFullscreenStatus()

    const vendorPrefix = ["", "moz", "webkit", "ms"]

    vendorPrefix.forEach(function(prefix) {
      document.addEventListener(
          prefix + "fullscreenchange",
          changeFullscreenStatus
      )
    })

    window.addEventListener("keydown", listener)

    return () => {
      vendorPrefix.forEach(function(prefix) {
        document.removeEventListener(
            prefix + "fullscreenchange",
            changeFullscreenStatus
        )
      })
      window.removeEventListener("keydown", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    fullScreenStatus,
    triggerFullscreen
  }
}
