import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [information, setInformation] = useState({});

    const setInfo = (newInfo) => {
        setInformation(newInfo);
    };

    const getInfo = () => {
        return information;
    };

    return (
        <AppContext.Provider value={{ setInfo, getInfo }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    return useContext(AppContext);
};
