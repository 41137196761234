import {useState, useEffect} from "react"

export function useTabFocusDetection({disabled} = {disabled: false}) {
    const [tabFocusStatus, setTabFocusStatus] = useState(true)


    useEffect(() => {
        if (disabled) return

        var browserPrefixes = ["moz", "ms", "o", "webkit"],
            isVisible = true // internal flag, defaults to true

        function getHiddenPropertyName(prefix) {
            return prefix ? prefix + "Hidden" : "hidden"
        }

        function getVisibilityEvent(prefix) {
            return (prefix ? prefix : "") + "visibilitychange"
        }

        function getBrowserPrefix() {
            for (var i = 0; i < browserPrefixes.length; i++) {
                if (getHiddenPropertyName(browserPrefixes[i]) in document) {
                    // return vendor prefix
                    return browserPrefixes[i]
                }
            }

            return null
        }

        // bind and handle events
        const browserPrefix = getBrowserPrefix()
        const hiddenPropertyName = getHiddenPropertyName(browserPrefix)
        const visibilityEventName = getVisibilityEvent(browserPrefix)

        function onVisible() {
            // prevent double execution
            if (isVisible) {
                return
            }

            // change flag value
            isVisible = true
            setTabFocusStatus(true)
        }

        function onHidden() {
            // prevent double execution
            if (!isVisible) {
                return
            }

            // change flag value
            isVisible = false
            setTabFocusStatus(false)
        }

        function handleVisibilityChange(forcedFlag) {
            // forcedFlag is a boolean when this event handler is triggered by a
            // focus or blur eventotherwise it's an Event object
            if (typeof forcedFlag === "boolean") {
                if (forcedFlag) {
                    return onVisible()
                }

                return onHidden()
            }

            // @ts-ignore
            if (document[hiddenPropertyName]) {
                return onHidden()
            }

            return onVisible()
        }

        document.addEventListener(
            visibilityEventName,
            handleVisibilityChange,
            false
        )

        // extra event listeners for better behaviour
        document.addEventListener(
            "focus",
            function () {
                handleVisibilityChange(true)
            },
            false
        )

        document.addEventListener(
            "blur",
            function () {
                handleVisibilityChange(false)
            },
            false
        )

        window.addEventListener(
            "focus",
            function () {
                handleVisibilityChange(true)
            },
            false
        )

        window.addEventListener(
            "blur",
            function () {
                handleVisibilityChange(false)
            },
            false
        )


        return () => {
        }
    }, [])

    return {
        tabFocusStatus
    }
}
