import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './global.scss'
import {BrowserRouter} from "react-router-dom";
import {store} from "./FaceID/faces/store";
import { Provider } from 'react-redux';
import {CookiesProvider} from "react-cookie";
import {AppProvider} from "./Store/Store";
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <AppProvider>
        <CookiesProvider>
      <BrowserRouter>
          <Provider store={store}>
          <App />
          </Provider>
      </BrowserRouter>
            </CookiesProvider>
        </AppProvider>

);

