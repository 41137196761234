// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iframe-content {
  position: absolute !important;
  z-index: 99999 !important;
  top: 10px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #fff;
}`, "",{"version":3,"sources":["webpack://./src/Exam/Exam.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,yBAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;AACF","sourcesContent":[".iframe-content{\r\n  position: absolute !important;\r\n  z-index: 99999 !important;\r\n  top: 10px;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100vh;\r\n  background: #fff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
