// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-info {
  display: flex;
  height: 40px;
  align-items: center;
  gap: 5px;
  line-height: normal !important;
  cursor: pointer;
}
.user-info .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.user-info .avatar img {
  width: 100%;
  height: 100%;
}
.user-info .basic-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.user-info .basic-info .name-user {
  font-size: 14px;
}
.user-info .basic-info .group-name {
  font-size: 11px;
}

.sidebar-collapse {
  position: absolute;
  z-index: 99999;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/home/home.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,mBAAA;EACA,QAAA;EACA,8BAAA;EACA,eAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;AAEJ;AADI;EACE,WAAA;EACA,YAAA;AAGN;AAAE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAEJ;AADI;EACE,eAAA;AAGN;AADI;EACE,eAAA;AAGN;;AACA;EACE,kBAAA;EACA,cAAA;EACA,YAAA;AAEF","sourcesContent":[".user-info{\r\n  display: flex;\r\n  height: 40px;\r\n  align-items: center;\r\n  gap: 5px;\r\n  line-height: normal !important;\r\n  cursor: pointer;\r\n  .avatar{\r\n    width: 40px;\r\n    height: 40px;\r\n    border-radius: 50%;\r\n    overflow: hidden;\r\n    img{\r\n      width: 100%;\r\n      height: 100%;\r\n    }\r\n  }\r\n  .basic-info{\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 5px;\r\n    .name-user{\r\n      font-size: 14px;\r\n    }\r\n    .group-name{\r\n      font-size: 11px;\r\n    }\r\n    }\r\n}\r\n.sidebar-collapse{\r\n  position: absolute;\r\n  z-index: 99999;\r\n  height: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
