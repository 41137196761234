// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #0005;
    z-index: 3;
    transition: visibility 0s ease-in-out 0s, opacity 0s ease-in-out 0s;
    visibility: hidden;
    opacity: 0;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
}
.loader svg {
    position: relative;
}
.loader-dialog {
    width: 90%;
    transition: margin-top 0s;
    margin-left: auto;
    margin-right: auto;
    margin-top: -14rem;
    margin-bottom: 4rem;
}
.loader-content{
    position: relative;
    width: 100%;
    border-radius: 0.375rem;
    background-color: rgb(255 255 255);
    box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.loader-body{
    padding: 2.5rem;
    text-align: center;
}
.loader-body p {
    font-size: 2em;
    letter-spacing: 6px;
    text-transform: uppercase;
    margin-top: 10px;
    color: #000;
}
.loader-body img{
    max-width: 100%;
    height: auto;
}
@media (min-width: 640px){
    .loader-dialog {
        width: 460px;
    }    
}
@media (max-width: 991px){
    .loader {
        height: 100%;
    }    
}
`, "",{"version":3,"sources":["webpack://./src/FaceID/css/Loader.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,UAAU;IACV,mEAAmE;IACnE,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,yBAAyB;IACzB,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,uBAAuB;IACvB,kCAAkC;IAClC,kGAAkG;AACtG;AACA;IACI,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,eAAe;IACf,YAAY;AAChB;AACA;IACI;QACI,YAAY;IAChB;AACJ;AACA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".loader {\r\n    position: absolute;\r\n    width: 100vw;\r\n    height: 100vh;\r\n    background-color: #0005;\r\n    z-index: 3;\r\n    transition: visibility 0s ease-in-out 0s, opacity 0s ease-in-out 0s;\r\n    visibility: hidden;\r\n    opacity: 0;\r\n    margin-top: 0px;\r\n    margin-left: 0px;\r\n    padding-left: 0px;\r\n}\r\n.loader svg {\r\n    position: relative;\r\n}\r\n.loader-dialog {\r\n    width: 90%;\r\n    transition: margin-top 0s;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n    margin-top: -14rem;\r\n    margin-bottom: 4rem;\r\n}\r\n.loader-content{\r\n    position: relative;\r\n    width: 100%;\r\n    border-radius: 0.375rem;\r\n    background-color: rgb(255 255 255);\r\n    box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);\r\n}\r\n.loader-body{\r\n    padding: 2.5rem;\r\n    text-align: center;\r\n}\r\n.loader-body p {\r\n    font-size: 2em;\r\n    letter-spacing: 6px;\r\n    text-transform: uppercase;\r\n    margin-top: 10px;\r\n    color: #000;\r\n}\r\n.loader-body img{\r\n    max-width: 100%;\r\n    height: auto;\r\n}\r\n@media (min-width: 640px){\r\n    .loader-dialog {\r\n        width: 460px;\r\n    }    \r\n}\r\n@media (max-width: 991px){\r\n    .loader {\r\n        height: 100%;\r\n    }    \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
