import React, {useEffect, useState} from 'react';
import {
    BookOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SettingOutlined,
    UploadOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Button, Layout, Menu, Popover, theme} from 'antd';
import './home.scss'
import {NavLink, Outlet, useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import {useAppContext} from "../Store/Store";



const {Header, Content, Footer, Sider} = Layout;
const items = [{
    key: 0, label: '',
},
    {
        label: "O'quv reja",
        icon: <BookOutlined/>,
        key: 1,
        children: [{
            label: <NavLink to={'/'}>Fanlar resurslari</NavLink>,
            key: 2,
            routename: "/",
        },
            {
                label: <NavLink to={'/exams'}>Imtihonlar</NavLink>,
                key: 3,
                routename: "/exams",
            },
            {
                label: <NavLink to={'/result'}>Imtihonlar natijalari</NavLink>,
                key: 4,
                routename: "/result",
            },],


    },
    {
        label: "Talaba ma'lumoti",
        icon: <UserOutlined/>,
        key: 5,
        children: [
            {
                label:<NavLink to='/privateinfo'>Shaxsiy ma'lumotlar</NavLink>,
                key: 6,
                routename: "/privateinfo",
            },],
    }, {
        label: "Tizim",
        icon: <SettingOutlined/>,
        key: 7,
        children: [{
            label: <NavLink to='/profile'>Profil</NavLink>,
            key: 8,
            routename: "/profile",
        },

        ],
    },]



const App = () => {
    const [openKeys, setOpenKeys] = useState();
    const location = useLocation();
    const currentroutename = location.pathname;
    const [selectMenuNum, setSelectMenu] = useState();
    const [collapse, setCollapse] = useState(false)
    const [collapsed, setCollapsed] = useState(false);
    const {setInfo, getInfo} = useAppContext()
    const navigate = useNavigate()
    const content = (
        <div>
            <Button style={{width:'100%'}} onClick={()=>navigate('/profile')}><UserOutlined/>Profil</Button>
            <Button style={{width:'100%',marginTop:'5px'}} onClick={()=>logOut()} danger><UploadOutlined rotate={90}/> Chiqish</Button>
        </div>
    );
    const handleMenuOpenChange = (key) => {
        setOpenKeys(key[key.length - 1]);
    };
    const {
        token: {colorBgContainer, borderRadiusLG},
    } = theme.useToken();
    const checkRouter = () => {
        items.forEach((item) => {
            // eslint-disable-next-line array-callback-return
            item.children?.map(function (child) {
                if (child.routename === currentroutename) {
                    setOpenKeys(item.key)
                    setSelectMenu(child.key);
                }
            })
        });
    };
    const logOut = ()=>{
        sessionStorage.clear()

        navigate('/login')
    }

    const getUser = () => {
        axios.get(`https://api.fastlms.uz/api/get/student/${sessionStorage.getItem('studentId')}/`, {
            headers: {
                // Authorization: `Bearer ${cookies.access}`
            }
        }).then((res) => {
            setInfo(res.data)
            sessionStorage.setItem('groupId', res.data.group.id)
            sessionStorage.setItem('student_uid_id', res.data.id)
        }).catch((error) => {

        });
    };
        useEffect(() => {
        checkRouter()
    }, [location.pathname]);

        useEffect(() => {
            getUser();
    }, []);


        return (<Layout>
            <Sider
                breakpoint="lg"
                collapsedWidth={window.innerWidth < 991 ? '0' : '64'}
                style={collapse && window.innerWidth < 991 ? {
                    position: 'absolute',
                    zIndex: 99999,
                    minHeight: '100vh',
                    height: '100%'
                } : {}}
                onBreakpoint={(broken) => {
                }}
                onCollapse={(collapsed, type) => {
                    setCollapse(!collapsed)
                    setCollapsed(collapsed)
                }}
                collapsed={collapsed}
            >

                <div className="demo-logo-vertical"/>
                <Menu theme="dark"
                      mode='inline'
                      openKeys={[`${openKeys}`]}
                      onOpenChange={handleMenuOpenChange}
                      selectedKeys={[`${selectMenuNum}`]}
                      style={{userSelect: 'none'}}
                      items={items}/>

            </Sider>
            <Layout>
                <Header
                    style={{
                        padding: 0,
                        background: colorBgContainer,
                        display: "flex",
                        justifyContent: 'space-between',
                        paddingRight: '15px',
                        alignItems: 'center'
                    }}
                >
                    <div>
                        {window.innerWidth < 991 ? <></> : <Button
                            type="text"
                            icon={collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{
                                fontSize: '16px',
                                width: 64,
                                height: 64,
                            }}
                        />
                        }
                    </div>

                    <Popover content={content} title="" trigger="click">
                        <div className='user-info'>
                            <div className='basic-info'>
                        <span className='name-user'>
                         {getInfo()?.first_name} {getInfo()?.second_name}
                        </span>

                                <span className="group-name">
                           {getInfo()?.group?.name}
                        </span>
                            </div>
                            <div className='avatar'>
                                <img src={getInfo()?.image} alt='user'/>
                            </div>
                        </div>
                    </Popover>
                </Header>
                <Content
                    style={{
                        margin: '24px 16px 0',
                    }}
                >
                    <div
                        style={{
                            padding: 24,
                            minHeight:window.innerHeight-(window.outerHeight - window.innerHeight),
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                            height: '100%',
                            overflow: "auto"
                        }}
                    >


                        <Outlet/>
                    </div>
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    © Smart Software - {new Date().getFullYear()}

                </Footer>
            </Layout>
        </Layout>);
    }
    ;
    export default App;