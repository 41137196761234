// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-resource {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.ant-card-head {
  background: #1677ff !important;
  color: #fff !important;
  min-height: 80px !important;
}

.resources {
  display: flex;
  gap: 5px;
  width: 100%;
  flex-wrap: wrap;
}

@media (max-width: 500px) {
  .resources {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/Resources/Resources.scss"],"names":[],"mappings":"AAAA;EACE,+EAAA;AACF;;AACA;EACE,8BAAA;EACA,sBAAA;EACA,2BAAA;AAEF;;AAAA;EACE,aAAA;EACA,QAAA;EACA,WAAA;EACA,eAAA;AAGF;;AADA;EACE;IACE,aAAA;IACA,mBAAA;IACA,uBAAA;EAIF;AACF","sourcesContent":[".card-resource{\r\n  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;\r\n}\r\n.ant-card-head{\r\n  background: #1677ff !important;\r\n  color: #fff !important;\r\n  min-height: 80px !important;\r\n}\r\n.resources{\r\n  display: flex;\r\n  gap: 5px;\r\n  width: 100%;\r\n  flex-wrap: wrap;\r\n}\r\n@media (max-width: 500px) {\r\n  .resources{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
