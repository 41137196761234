// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-react .video-react-big-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.buttons-resource {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.videos {
  display: flex;
  flex-direction: column;
}
.videos .video-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 7px 10px;
  border: 1px solid white;
  border-radius: 7px;
  transition: 0.1s linear;
  margin-bottom: 7px;
}
.videos .video-item:hover {
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.plyr__video-wrapper {
  max-height: 100vh !important;
}

video {
  object-fit: contain;
}

.spinner .ant-spin {
  background: rgba(255, 255, 255, 0.96);
  height: 100% !important;
  max-height: inherit !important;
}`, "",{"version":3,"sources":["webpack://./src/Resources/Uploaded-resurces/UplodedResources.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,4CAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;AACF;;AACA;EACE,aAAA;EACA,sBAAA;AAEF;AADE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,kBAAA;EACA,uBAAA;EACA,kBAAA;AAGJ;AAFI;EACE,eAAA;EACA,iDAAA;AAIN;;AAAA;EACE,4BAAA;AAGF;;AAAA;EACE,mBAAA;AAGF;;AAAE;EACE,qCAAA;EACA,uBAAA;EACA,8BAAA;AAGJ","sourcesContent":[".video-react .video-react-big-play-button{\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translateX(-50%) translateY(-50%);\r\n}\r\n\r\n.buttons-resource{\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  margin-bottom: 5px;\r\n}\r\n.videos{\r\n  display: flex;\r\n  flex-direction: column;\r\n  .video-item{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    font-size: 18px;\r\n    padding: 7px 10px;\r\n    border: 1px solid white;\r\n    border-radius: 7px;\r\n    transition: 0.1s linear;\r\n    margin-bottom: 7px;\r\n    &:hover {\r\n      cursor: pointer;\r\n      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\r\n    }\r\n  }\r\n}\r\n.plyr__video-wrapper{\r\n  max-height: 100vh !important;\r\n\r\n}\r\nvideo{\r\n  object-fit: contain;\r\n}\r\n.spinner{\r\n  .ant-spin{\r\n    background: rgba(255, 255, 255, 0.96);\r\n    height: 100% !important;\r\n    max-height: inherit !important;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
