// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-card-head{
    height: 80px !important;
    background: #1677ff !important;
}
.ant-card-body{
    background: #f4f4f4;
}
.button-alian:hover{
    color: #28C76F !important;
    border: 1px solid #28C76F !important;
}`, "",{"version":3,"sources":["webpack://./src/Resources/resources-list-item/subject-item.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,8BAA8B;AAClC;AACA;IACI,mBAAmB;AACvB;AACA;IACI,yBAAyB;IACzB,oCAAoC;AACxC","sourcesContent":[".ant-card-head{\r\n    height: 80px !important;\r\n    background: #1677ff !important;\r\n}\r\n.ant-card-body{\r\n    background: #f4f4f4;\r\n}\r\n.button-alian:hover{\r\n    color: #28C76F !important;\r\n    border: 1px solid #28C76F !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
