import { Navigate, useLocation } from 'react-router-dom'
import isTokenExpired from "./TokenService";
export const RequireAuth = ({ children }) => {
  const location = useLocation()
  const access = sessionStorage.getItem('access')
  //     ((access!==null||refresh!==null)&&(!isTokenExpired(access)||!isTokenExpired(refresh)))
  if (!(access!==null&&!isTokenExpired(access))) {
    return <Navigate to='/login' state={{ path: location.pathname }} />
  }
  else {
    return children
  }
}