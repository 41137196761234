import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Table,ConfigProvider} from "antd";
import axios from "axios";
import './ResourcesList.scss'
import {useAuth} from "../../auth/AuthProvider";
import {setDocumentTitle} from "../../Store/DocumentTitle";

const ResourcesList = () => {
    const {id} = useParams()
    const [subjects,setSubjects] = useState([])
    const {user,getUser} = useAuth()
    const navigate = useNavigate();
    const columns = [
        {
            title: 'Mavzu nomi',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Xolat',
            dataIndex: 'age',
            key: 'age',
            width:'5%',
            render: (_, record) => (
                <>
                    <div className={record.in_progress?'active true':'active'}>
                    </div>
                </>
            ),
        },
        {
            title: 'Resurslarni ko\'rish',
            dataIndex: 'address',
            key: 'address',
            width:'13%',
            render: (_, record) => (
                <>
                    <Button style={{width:'100%'}} onClick={()=>enter(record.id)} type='primary' size={"large"}>
                       Resurs
                    </Button>
                </>
            ),
        },
        {
            title: 'Dars xonaga kirish',
            key: 'tags',
            dataIndex: 'tags',
            width:'8%',
            render: (_, record) => (
                <>
                 <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: `#28C76F`,
            colorPrimaryHover: `#28C76F`,
            colorPrimaryActive: `#28C76F`,
            lineWidth: 0,
          },
        },
      }}
    >
      <Button disabled={record.topic_bigbluebutton?false:true} onClick={()=>enterBBB(record.topic_bigbluebutton,record.id)}  type='primary' size={"large"}>
                      Dars xonaga kirish
                  </Button>
    </ConfigProvider>
                 
                </>
            ),
        },
    ];
    const  getSubjects = () => {
        axios.get(`https://api.fastlms.uz/api/student/content/topic/get_all/?content_id=${id}&group_id=${sessionStorage.getItem('groupId')}`)
            .then((res) => {
                setSubjects(res.data.results)
            })
            .catch(() => {
            });
    }
    const enterBBB =(id,mid)=>{
        axios.get(`https://api.fastlms.uz/api/student/topic/bigbluebutton/?bbb_id=${id}&group_id=${sessionStorage.getItem('groupId')}&topic_id=${mid}`).then(()=>{
            join(id)
        }).catch(()=>{

        })
    }
    const join=(id)=>{
        axios.post(`https://api.fastlms.uz/api/bigbluebutton/join/attendee/`, {
            username: user.full_name,
            id: id
        }).then((res) => {
            window.open(res.data.url)
        }).catch(() => {
            // this.errorNotification("Dars xona mavjud emas")
        })
    }
    const enter=(id)=>{
        navigate(`/uploaded-resources/${id}`)
        localStorage.removeItem('currentVideoId')
        localStorage.removeItem('currentVideoFile')
    }
    useEffect(() => {
        setDocumentTitle('Resurslar')
        getSubjects()
        getUser()
    }, []);
    return (
        <div className='resources-list'>
            <Table scroll={{
                x: 1000
            }} columns={columns} pagination={false} dataSource={subjects}/>
        </div>
    );
};

export default ResourcesList;